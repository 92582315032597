export const NAVIGATION = {
  workforce: [
    {
      id: 1,
      routeName: "listCampaign",
      text: "Campañas",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 2,
      routeName: "commissions",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 2,
      routeName: "configClient",
      text: "Clientes",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 3,
      routeName: "activity",
      text: "Actividad",
      count: true,
      icon: "",
      subItem: [],
    },
    {
      id: 6,
      routeName: "addUser",
      text: "Usuarios",
      count: true,
      icon: "",
      subItem: [],
    },
    // {
    //   id: 5,
    //   routeName: "changePassword",
    //   text: "Cambiar contraseña",
    //   count: true,
    //   icon: "",
    //   subItem: [],
    // },
  ],
  directory: [
    {
      id: 1,
      routeName: "commissionsDirectory",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    // {
    //   id: 2,
    //   routeName: "changePassword1",
    //   text: "Cambiar contraseña",
    //   count: true,
    //   icon: "",
    //   subItem: [],
    // },
  ],
  manager: [
    {
      id: 1,
      routeName: "commissionsManager",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    // {
    //   id: 2,
    //   routeName: "changePassword2",
    //   text: "Cambiar contraseña",
    //   count: true,
    //   icon: "",
    //   subItem: [],
    // },
  ],
   payRollManager: [
    {
      id: 1,
      routeName: "commissionsPayRollManager",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    // {
    //   id: 2,
    //   routeName: "changePassword3",
    //   text: "Cambiar contraseña",
    //   count: true,
    //   icon: "",
    //   subItem: [],
    // },
  ],
  coordinator: [
    {
      id: 1,
      routeName: "commissionsCoordinator",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    // {
    //   id: 2,
    //   routeName: "changePassword4",
    //   text: "Cambiar contraseña",
    //   count: true,
    //   icon: "",
    //   subItem: [],
    // },
  ],
  accountManager: [
    {
      id: 1,
      routeName: "commissionsAccountManager",
      text: "Comisiones",
      count: true,
      icon: "",
      subItem: [],
    },
    // {
    //   id: 2,
    //   routeName: "changePassword5",
    //   text: "Cambiar contraseña",
    //   count: true,
    //   icon: "",
    //   subItem: [],
    // },
  ],
};

export const LISTMONTH = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPRIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];

export const ROL = {
  workforce: 4,
  directory: 1,
  manager: 2,
  payRollManager: 3,
  coordinator: 5,
  accountManager: 6
};

export const STATUS = {
  active: 1,
  expired: 2,
  approved: 3,
  rejected: 4,
  read: 5,
  noRead: 6,
  rejectedDirectory: 7,
  rejectedManager: 8,
  approvedDirectory: 9,
  completed: 10,
  pendingApprovedDirectory: 11,
  pendingActivation_mrChip: 12,
  inactive_mrChip: 13,
  active_mrChip: 14,
  completed: 15,
  deleted: 15,
  rejectedCoordinator: 16, 
  approvedCoordinator: 17,
  pendingCoordinator: 18,
  rejectedAccountManager: 19,
  approvedAccountManager: 20,
  pending: 21,
  pendingAccountManager: 22,
  delete: 23,
  cancel: 24
};

export const ACTIONS = {
  manager: null,
  payrollManager: null,
  directory: 1,
  workforce: 4,
  coordinator: null,
  accountManager: null,
}

export const REASONREJECTED = {
  rejectedCommision: 1
}
